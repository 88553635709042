@import "variables";

html {
  min-height: 100%;
}

#root {
  min-height: 95vh;
}

body,
#react-loader {
  height: 100vh;
  margin: 0;

  background: $body-bg;
}

footer {
  display: none;
}

#react-loader {
  pointer-events: none;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 500ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

#react-loader.hide {
  opacity: 0;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border: 1.1em solid #e5f5ff;
  border-left: 1.1em solid #475f7b;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
